@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "./assets/scss/spreadsheet.scss";

// VARIABLES

$padding: .5rem; 
$margin: .5rem;
$icon-height: 1.5rem;

$standard-font-size: 12px;
$content-font-size: 14px;
$br: 5px;
$br-round: 100px;

$base-col: white;
$base-col-mir: black;
$base-font-col: black;
$base-font-col-mir: white;
$gray-font: #ececf1;
$primary-col: #673AB7;
$secondary-col: #F3F1FA;
$accent-gray: #d2d2d2;
$dark-gray: #2e343c;
$bright-gray-accent: whitesmoke;

$header-height: 3.5rem;
$logo-width: 8rem;
$logo-height: 1.25rem;
$user-button-dimension: 2rem;
$subheader-height: 1.2rem;
$sidebar-width: 15rem;
$mini-sidebar-width: 4rem;
$toolbar-height: 40px;
$sidebar-margin-top: 3.4rem;
$index-item-height: 3rem;
$index-wrapper-item: 2rem;
$sidebar-icon-height: 1.3rem;
$drop-import-height: 6rem;
$chat-width: 35vw;
$gutter-width: 1rem;
$plot-max-width: 500px;
$plot-max-height: 300px;

.gutter {
  background-color: $base-col;
  background-repeat: no-repeat;
  background-position: 50%;
  z-index: 11;
  &.gutter-horizontal {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
    cursor: col-resize;
  }
}

// GENERAL 

body {
    font-family: "proxima-nova", sans-serif;
    font-weight: 400;
    font-style: normal;
    max-width: 100vw;
    overflow: hidden;
}

label {
    padding: $padding/2 $padding;
    color: $dark-gray;
    font-size: $standard-font-size;
    letter-spacing: .025rem;
    margin-top: $margin;
}

#content-wrapper {
  padding-left: 0;
  transition: all .3s;
  height: 100vh;
  .sidebar-toggle {
    img {
      transform: rotate(0deg);
    }
  }
  .sidebar-toggle-mini {
    position: absolute;
    z-index: 15;
    left: 0;
    top: calc(50vh - $icon-height);
    padding: $padding;
    filter: invert(0);
    transform: rotate(0deg);
    cursor: pointer;
    transition: all .3s;
    img {
      max-height: $icon-height * 2;
    }
  }

  &.sidebar-off {
    padding-left: $sidebar-width;
    .navbar {
      margin-left: $margin * 2;
    }
    .sidebar-toggle {
      margin-left: 0;
      span {
        display: none;
      }
      img {
        transform: rotate(180deg);
      }
    }
    .sidebar-toggle-mini {
      transform: rotate(180deg);
    }
    .sub-header .sub-head {

    }

    .mini-logo {
      display: none !important;
      right: $logo-height * 2.1 ;
    }
  }
}

// UI COLOR SCHEME

.table.slide, .toolbar, 
.x-spreadsheet-toolbar, .vhi_element_wrapper {
  background: $base-col !important;
}

#content-wrapper {
  background: $base-col;
}

.sidebar, .sheet_dd.loaded {
  background: $secondary-col !important;
}



// HEADER

header {
  .btn {
    font-size: $content-font-size*1.1;
    margin-left: $margin;
    padding: 0;
  }

  .sidebar-user {
    position: absolute;
    left: -$sidebar-width - $margin * 2;
    top: calc(100vh - $header-height * 0.7 - $user-button-dimension / 2);
    z-index: 10;
    #page-header-user-dropdown {
      border: 1px solid white;
    }
    .dropdown-menu {
      position: absolute;
      left: 3rem;
      top: initial;
      bottom: 2rem;
    }
  }
}

.logo {
  margin-left: 0;
  margin-right: $margin * 2;
  z-index: 11;
  transition: all 0.3s;
  display: flex;
  a {
    display: flex;
  }
  img {
    height: $logo-height;
    max-height: none !important;
  }
  img:first-child {
    display: block;
  }
  img:nth-child(2) {
    display: none;
  }
}

.navbar {
    padding: 0 $padding * 2 0 0;
    margin-left: $margin * 4;
    height: $header-height;
    position: relative;
    transition: all 0.3s;
    z-index: 15;

    img {
      max-height: 1.5rem;
    }

    > .d-flex {
      margin-left: $margin;
      > .logo {
      display: flex;
      align-items: center;
      }
    } 

    .topbar-user {
      display: none !important;
    }

    .sidebar-toggle {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $content-font-size;
      width: 2rem;
      color: $base-font-col;
      border-radius: $br;
      cursor: pointer;
      position: absolute;
      left: -$margin * 7;
      top: $margin * 1.25;
      z-index: 15;
      transition: all .3s;
      img {
        height: $icon-height * 1.5;
        max-height: none;
        filter: invert(0);
        transform: rotate(180deg);
      }
      &:hover {
        background: $secondary-col;
      }
    }

    > .d-flex {
      .dropdown {
        z-index: 12;
        > .btn {
          font-weight: bold;
          color: $primary-col;
        }
      }
    }

    .notifications, .help {
      display: none !important;
    }

    #page-header-user-dropdown, .notifications, .share, .help {
      border-radius: 100%;
      width: $user-button-dimension;
      height: $user-button-dimension;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: $margin / 2;
      background: $base-col;
      &:hover {
        background: $secondary-col;
        img {
          filter: invert(0);
        }
      }
    }

    .notifications {
      img {
        max-height: 1.5rem;
      }
      .topbar-badge {
        background: $primary-col !important;
        border-radius: 100%;
        transform: translate(50%, -50%);
      }
    }

    .dropdown {
      display: flex;
      a {
        cursor: pointer;
      }
    }

    .dropdown-menu-end {
      width: 296px;
      max-width: 296px;
      padding: $padding * 1.5;
      margin-top: $margin / 1.5;
      font-size: $content-font-size;
      img {
        max-height: 1rem;
        margin-right: $margin;
      }
      .dropdown-item {
        padding: $padding;
      }
    }
}

#page-header-user-dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    margin-left: $margin * 2 !important;
    border-radius: 100%;
    border: 1px solid $primary-col;
    background: $primary-col !important;
    overflow: hidden;
    > .d-flex {
        color: $base-font-col-mir;
        font-size: $content-font-size * 1.5;
    }
    .dropdown-toggle::after {
        display: none !important;
    }
    > ::after {
      margin-left: -0.745em !important;
      color: transparent !important;
    }
    .dropdown-menu {
      box-shadow: 0 1px 2px hsla(209, 28%, 39%, 0.2);
    }
}

.dropdown-toggle:after {
  margin-bottom: -$margin/3;
}

#page-header-user-dropdown::after {
  display: none !important;
}

.container-fluid {
    padding: 0;
}

// DROPDOWN MENUS

.dropdown-item {
  i {
    margin-right: $margin;
  }
}

// SHARE 

.share-dataset {
  //display: flex;
  display: none;
  align-items: center;
  padding: $padding / 2 $padding * 2;
  border-radius: 9986px;
  cursor: pointer;
  img {
    max-height: $icon-height * .75;
    margin-right: $margin / 2;
  }
  &:hover {
    background: $secondary-col;
    img {
      filter: invert(0);
    }
  }
}

.modal-content.share-dateset {
  justify-content: flex-start;
  .modal-body {
    padding: $padding * 2;
  }
}


// MODALS 

.modal-content {
  .modal-header {
        h4 {
      margin: 0;
    }
  }

  .integration-list {
    .integration-item {
      display: flex;
      align-items: center;
      img {
        max-height: 2rem;
        margin-right: $margin * 2;
      }
      button {
        margin-left: auto;
        background: $secondary-col;
        border-radius: $br;
        border: 0;
        padding: $padding $padding * 2;
        &:hover {
          background: $primary-col;
          color: $base-font-col-mir;
        }
      }
    }
  }

}

// CREDIT OVERVIEW

.credit-count {
  display: none;
  align-items: center;
  background: $bright-gray-accent;
  padding: $padding / 2 $padding;
  margin-left: $margin;
  border-radius: $br;
  cursor: pointer;
  position: relative;
  img {
    max-height: 1.5rem;
  }
  .hover-tooltip {
    display: none;
    position: absolute; 
    color: $base-font-col;
    white-space: nowrap;
    bottom: -$standard-font-size * 3.5;
    right: 0;
    padding: $padding / 2 $padding;
    border-radius: $br;
    z-index: 10;
  }
  
  &:hover {
    background: $secondary-col;
    .hover-tooltip {
      display: flex;
    }
  }
}

// SUB HEADER

body .sub-header {
    position: absolute;
    background: $base-col;
    width: 100%;
    height: $header-height;
    top: -$header-height;
    left: 0;
    padding-right: 10em;

    .sub-head {
        background: $base-col;
        height: $header-height;
        padding: 0 $padding 0 0;
        box-shadow: none;
        position: relative;
        bottom: 0;
        left: 0;
        transition: all .3s;
        z-index: 11;
        width: 100%;
        overflow: auto;
        //overflow: hidden;
        .table-interactions {
          & > div {
            cursor: pointer;
          }
        }

    // SHEET-SLIDES 

    > .dropdown {
      margin: 0 0 0 0 !important;
      padding: 0 $padding * 2 0 0;
      z-index: 12;
      .dropdown-popup {
        position: absolute;
        top: 100%;
        background: #fff;
        z-index: 14;
        padding: $padding * 1.5;
        border: 1px solid $accent-gray;
        border-radius: 3px;
        .dropdown-item {
          padding: $padding !important;
          img {
            margin-right: $margin;
          }
        }
      }
    }

    .sheet_dd {
        background: transparent;
        color: $dark-gray;
        border: none;
        border-radius: $br;
        padding: 0;
        font-size: $content-font-size;
        .btn {
          font-size: $content-font-size;
        }
    } 
    .loaded {
        color: $base-font-col;
        padding: $padding/2 $padding $padding/2 $padding*2;
        border: none;
    }
    .dropdown-toggle::after {
        margin-left: $margin;
        vertical-align: $padding / 2;
        display: none;
    }
    .dropdown-toggle.loaded::after {
        display: inline-block;
    }
    lord-icon {
        margin-right: $margin/2;
        margin-left: -$margin;
        margin-bottom: $margin/5;
        height: 1.25rem;
        width: 1.25rem;
        filter: invert(1);
    }
    > .right {
        lord-icon {
            height: 1.2rem;
            width: 1.2rem;
            margin: 0;
        }
      .share {
        lord-icon {
          filter: invert(0);
        }
      }
      .share:hover {
        lord-icon {
          filter: invert(0);
        }
      }
    }

    #add-sheet {
      padding: 0 0 0 0;
      margin: 0 0 0 $margin;
    }
  }


    // EXPORT FUNCTIONS
    .export-spreadsheet {
      background: $secondary-col;
      position: absolute;
      display: flex;
      align-items: center;
      font-size: 13px;
      padding: $padding / 2 $padding;
      border-radius: $br;
      bottom: $margin * 1.8;
      right: $margin * 2;
      z-index: 2;
      cursor: pointer;
      lord-icon {
        width: 1.25rem;
        height: 1.25rem;
        padding: 0;
        background: transparent !important;
      }
      &:hover {
        background: $primary-col;
        > * {
          filter: invert(1);
        }
      }

      &.share-workspace {
        right: $margin * 3 + 1.25rem + $padding * 2;
      }

      &.clean-data {
        right: $margin * 4 + 1.25rem * 2 + $padding * 4;
      }

    }
}

// DOWNLOAD DATASET 

.download-dataset {
  
  .download-buttons {
    display: flex;
    flex-direction: row; 
    justify-content: center;
    margin-bottom: $margin * 2;
    button {
      display: flex;
      align-items: center;
      max-width: 50%;
      margin-right: $margin * 2;
      background: transparent; 
      border: 1px solid $accent-gray;
      color: black;
      lord-icon {
        margin-right: $margin;
      }
      &:hover {
        background: $primary-col;
        color: $base-font-col-mir;
        lord-icon {
          filter: invert(1);
        }
      }
    }
  }

}

// DETAIL - SIDEBAR - TOGGLE 

.workspaces-on, .dataspace-on, .integrations-on {

  &#content-wrapper {
    padding-left: $mini-sidebar-width + $sidebar-width !important;
  }

  .new-sidebar-item {
    display: none !important;
  }

  .sidebar-user {
    margin-left: - $mini-sidebar-width;
    transition: .3s all;
  }

  .new-workspace {
    .logo {
      margin: auto !important;
      img:first-child {
        display: none;
      }
      img:nth-child(2) {
        display: block;
      }
    }
    .new-sidebar-item {
      display: none !important;
    }
  }

  .index-item.workspaces, .index-item.dataspace, .index-item.integrations, .drop-import,
  .index-wrapper.workspaces-mini-index, .index-wrapper.dataspace-mini-index, .integrations-list {
    width: initial;
  }

  .detail-sidebar {
    display: none;
    left: - $sidebar-width !important;
    z-index: 12 !important;
      .index-item {
      }

      .index-item.workspaces, .index-item.dataspace, .index-item.integrations, .drop-import,
      .index-wrapper.workspaces-mini-index, .index-wrapper.dataspace-mini-index, .integrations-list {
        position: static !important;
      }

    }

  .sidebar {
    min-width: $mini-sidebar-width !important;
    max-width: $mini-sidebar-width !important;
    left: - $mini-sidebar-width - $sidebar-width !important;
    background: $secondary-col !important;
    transition: .3s all;
    .index-item {
      justify-content: center;
      label {
        display: none;
      }
      // margin-bottom: $margin * 2;
    }
    .index-wrapper, .integrations-list, .drop-import  {
      display: none !important;
      margin-bottom: $margin * 4 !important;
    }
  }
}

.workspaces-on {
  .sidebar {
    .index-item.workspaces {
      background: $base-col !important;
    }
  }
  .detail-sidebar.workspaces-sidebar {
    display: block !important;
  }
}

.dataspace-on {
  .sidebar {
    .index-item.dataspace {
      background: $base-col !important;
    }
  }
  .detail-sidebar.dataspace-sidebar {
    display: block !important;
  }
}

.integrations-on {
  .sidebar {
    .index-item.integrations {
      background: $base-col !important;
    }
  }
  .detail-sidebar.integrations-sidebar {
    display: block !important;
  }
}

// CONTENT 

#content {
    max-width: 100dvw;
    height: calc(100dvh - $header-height);
    max-height: calc(100dvh - $header-height);
    position: relative;

    // BG VIDEO 

    .bg_video {
      position: absolute;
      top: -$toolbar-height;
      left: -$sidebar-width;
      width: calc(100% + $sidebar-width);
      height: calc(100% + $toolbar-height);
      video {
        position: absolute;
        width: 200%;
        left: 0;
        top: 0;
      }
    }

    // TOOLBARS
    .toolbar, .x-spreadsheet-toolbar {
        display: flex;
        align-items: center;
        width: 100%;
        height: $toolbar-height;
        border-bottom: none;
        .x-spreadsheet-toolbar-btn {
            font-family: "proxima-nova", sans-serif;
            margin: 0 $margin/5 0 0 ;
        } 
    }
    .x-spreadsheet-toolbar {
      // border-bottom: 1px solid $accent-gray;
    }
    .toolbar {
      // border-bottom: 1px solid $accent-gray;
      > div {
        background: transparent;
        font-size: $standard-font-size;
        display: flex;
        height: 2.4em;
        margin-left: .5em;
        align-items: center;
        cursor: pointer;
        padding: $padding/2 $padding;
        border-radius: $br;
      }
      >div:hover {
        background: $secondary-col;
      }
      lord-icon {
        height: 1rem;
        width: 1rem;
        margin-right: $margin/2;
      }
    }

    .data-source-type-icon {
      max-width: $sidebar-icon-height; 
      max-height: $sidebar-icon-height;
      height: $sidebar-icon-height;
      width: $sidebar-icon-height;
      position: absolute;
      left: 0;
      top: 0;
      margin-right: $margin / 2;
      &:after {
        content: '';
        background-image: url("/assets/img/integration-logos/excel.png");
        background-size: cover;
        height: inherit;
        width: inherit;
        position: absolute;
        top: 0;
        left: 0;
      }
      &.xls:after, &.xlsx:after {
        background-image: url("/assets/img/integration-logos/excel.png");
      }
      &.numbers:after {
        background-image: url("/assets/img/integration-logos/numbers.png");
      }
      &.pdf:after {
        background-image: url("/assets/img/integration-logos/pdf.png");
      }
      &.csv:after {
        background-image: url("/assets/img/integration-logos/csv.png");
      }
      &.loading:after {
        background-image: none;
      }
    }

    // SIDEBAR
    .sidebar, .detail-sidebar {
        background: transparent;
        min-width: $sidebar-width;
        max-width: $sidebar-width;
        min-height: calc(100vh);
        max-height: calc(100vh);
        left: - calc($sidebar-width);
        top: - $header-height;
        overflow: auto;
        width: initial;
        position: absolute;
        padding: $padding 0 $header-height 0;
        overflow: visible;
        display: flex;
        flex-direction: column; 
        z-index: 13;

        * {
          transition: .3s all;
          white-space: nowrap;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          background: $primary-col;
          width: 100%;
          height: $header-height * 0.7;
        }

        // TOOLBAR 
        .toolbar {
          position: absolute;
          top: calc($header-height);
          left: 0;
          width: 100%;
          height: $subheader-height;
          background: transparent;
          .tab {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
            color: $base-font-col;
            padding: 0.25rem 1rem 0.25rem 1rem;
            width: 50%;
            border: none;
            border-radius: 0 10px 0 0;
            margin: 0;
            margin-bottom: -$padding * 1.5;
            &:not(:first-child) {
              border-radius: 10px 0 0 0;
            }
            &.active {
              font-weight: bold;
            }
          }
        }

        // DRAG AND DROP FILES

        .dragover-data {
          display: none;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: $sidebar-width;
          height: 10rem;
          position: absolute;
          left: 0; 
          pointer-events: none;
          top: calc(50% - 10rem);
          z-index: 20;
          lord-icon {
            height: 4rem;
            width: 4rem;
            margin-bottom: $margin * 2;
          }
          p {
            flex-direction: column;
            font-weight: bold;
            color: $primary-col;
            text-align: center;
            max-width: 80%;
            white-space: wrap !important;
            span {
              display: none;
              max-width: 100%;
              font-weight: normal;
              font-size: $standard-font-size;
              white-space: wrap !important;
            }
          }
        }

        &.dragover {
          background: $secondary-col !important;
          * {
            display: none;
          }
          .new-workspace .logo, .new-workspace .logo > img:first-child {
            display: flex !important;
            margin-top: $margin * .2;
          }
          .dragover-data, .dragover-data * {
            display: flex !important;
          }
          &.drag-invalid {
            background-color: rgb(253, 141, 141) !important;
            cursor: no-drop !important;
            .dragover-data {
              border: 2px dashed red;
            }
          }
        }

        // SIDEBAR WRAPPER 
        .sidebar-wrapper {        
          display: flex;
          flex-direction: column;
          height: 100%;
          overflow: hidden;
          flex-grow: 1;
          .h-label {
            display: flex;
            align-items: center;
            position: absolute;
            top: calc($header-height + $padding*1.5);
            left: $padding;
            h2 {
              color: $primary-col;
              font-size: $standard-font-size;
              margin: 0 0 0 $margin/2;
            }
          }
        }

        // NEW WORKSPACE

        .new-workspace {
          display: flex;
          align-items: center;
          padding: $padding;
          margin: 0 $margin;
          border-radius: 5px;
          cursor: pointer; 
          &:hover {
            // background: $secondary-col;
          }
        }

        .new-sidebar-item {
          position: absolute;
          right: $margin * 1.5; 
          top: $header-height + $margin * .9;
          z-index: 11;
        }

        // INDEX ELEMENT POSITIONS

        .index-item.workspaces, .index-item.dataspace, .index-item.integrations, .drop-import,
        .index-wrapper.workspaces-mini-index, .index-wrapper.dataspace-mini-index, .integrations-list {
          width: calc(100% - $margin * 2);
          transition: .3s all;
        }

        .drop-import {
          bottom: $header-height * 0.7;
        }

        // MINI LOGO 

        .mini-logo {
          display: block;
          cursor: pointer;
          position: absolute; 
          top: $margin * 1.75;
          right: -$logo-height * 2.1 ;
          transition: 0.1s all;
          z-index: 12;
          img {
            width: $logo-height;
          }
        }

        // FILE-IMPORT 
        .drop-import {
            position: absolute;
            width: 100%;
            height: $drop-import-height;
            max-height: $drop-import-height;
            background: transparent;
            padding: $padding * 2;
            font-size: .8rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            mask-image: linear-gradient(to left, transparent 0%, black 15%);
            lord-icon {
                height: 2rem;
                width: 2rem;
                min-width: 2rem;
                filter: invert(0);
            }
            span {
                margin-left: $margin * 2;
                font-size: $content-font-size;
                max-width: calc(100% - $padding * 4);
                white-space: nowrap;
            }
            &.dragover {
              animation: tilt-shaking 1s linear infinite;
              background: $primary-col;
            }

            @keyframes tilt-shaking {
              0% {
                box-shadow: 0 0 0 0px $secondary-col;
              }
              100% {
                box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
              }
            }

            &.drop {

              @keyframes rotate {
                100% {
                  transform: rotate(1turn);
                }
              }

                background: $primary-col;
                position: relative;
                z-index: 0;
                overflow: hidden;
                
                &::before {
                  content: '';
                  position: absolute;
                  z-index: -2;
                  left: -50%;
                  top: -50%;
                  width: 200%;
                  height: 200%;
                  background-color: $primary-col;
                  background-repeat: no-repeat;
                  background-size: 50% 50%, 50% 50%;
                  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
                  background-image: linear-gradient($primary-col, $primary-col), linear-gradient($primary-col, $primary-col), linear-gradient($primary-col, $primary-col), linear-gradient($primary-col, $primary-col);
                  animation: rotate 4s linear infinite;
                }
                
                &::after {
                  content: '';
                  position: absolute;
                  z-index: -1;
                  left: 3px;
                  top: 3px;
                  width: calc(100% - 6px);
                  height: calc(100% - 6px);
                  background: $secondary-col;
                  border-radius: 3px;
                }
              }
        }

        // INDEX 

        .index-item {
          position: relative;
          margin: $margin * 1.5 $margin 0 $margin;
          padding: $padding / 2;
          display: flex;
          align-items: center;
          cursor: pointer;
          width: calc(100% - 1rem);
          lord-icon {
            max-height: $content-font-size * 2;
          }
          label {
            font-size: $content-font-size;
            cursor: pointer;
          }

          &:hover {
            border-radius: 5px;
            background: $secondary-col;
          }
        }

        .index-wrapper {
          //max-height: $index-wrapper-item * 4;
          flex-grow: 1;
          background: transparent;
          margin: 0 $margin $margin * 8 $margin;
          padding: 0 0 $padding 0;
          border-radius: $br;
          position: relative;
          // -webkit-mask: linear-gradient(to right, black calc(100% - 30px), transparent);
          > section:nth-child(n+5) {
            //display: none;
          }

          > section {
            &.uploading > a {
              background: $secondary-col; 
              * {
                color: $base-font-col;
              }
              > div {
                mask-image: linear-gradient(to left, transparent 10%, black 30%);
              }
              &::after {
                background-image: linear-gradient(to right, rgba(255,0,0,0),  rgba(255,0,0,0));
              }
            }
          }

          &.dataspace-mini-index, &.dataspace-index {
            a {
              padding-left: $padding * 5;
              position: relative;
            }
          }

          .data-source-type-icon {
            left: $padding;
            top: $padding;
          }
        }

        label {
          color: $primary-col;
          margin-top: 0;
        }

        .new-sidebar-item {
          display: flex;
          align-items: center;
          a {
            padding: $padding 0;
            display: flex;
            justify-content: center;
            margin: 0;
            padding: 0;
            lord-icon {
              margin: 0;
            }
          }
          #new-sheet {
            transform: scale(0.8);
          }
        }

        section {
          max-height: $index-wrapper-item;
          height: $index-wrapper-item;
        }

        a {
            color: $dark-gray;
            padding: $padding $padding;
            cursor: pointer;
            display: flex;
            font-size: $content-font-size;
            align-items: center;
            margin-bottom: .05rem;
            text-wrap: nowrap;
            text-decoration: none;
            position: relative;

            lord-icon {
                filter: invert(0);
                margin-right: $margin;
                height: 1.5rem;
                width: 1.5rem;
            }
            > div {
                width: 100%;
                display: flex;
                align-items: center;
                font-size: $content-font-size;
                span {
                  margin-left: auto;
                }
            }
            .table-item {
              mask-image: linear-gradient(to left, transparent 0%, black 15%);
            }

            .dropdown {
              position: absolute;
              right: 0;
              .dropdown-toggle {
                .bi-three-dots {
                  display: none; 
                  color: $base-font-col-mir;
                }
              }
              .dropdown-toggle::after {
                display: none;
              } 
              div {
                padding: $padding $padding*2;
              }
              div:hover {
                background: $bright-gray-accent;
              }
            }
        }
        a.active {
            font-weight: bold;
            background: transparent;
            color: $base-font-col;
            lord-icon {
                filter: invert(0);
            }
            .dropdown {
              div {
                font-weight: normal;
              }
            }
        }
        a:hover, .folder:hover {
            background: $secondary-col; 
            cursor: pointer;
            * {
              color: $base-font-col;
            }
            lord-icon {
              filter: invert(0);
            }
            > div {
              mask-image: linear-gradient(to left, transparent 10%, black 30%);
            }
            > span:nth-child(n+2) {
              display: block;
            }
            .dropdown-toggle {
              z-index: 20;
              .bi-three-dots {
                display: flex; 
                color: $base-font-col;
              }
            }
            &::after {
              background-image: linear-gradient(to right, rgba(255,0,0,0),  rgba(255,0,0,0));
            }
        }

        #new-sheet, #new-folder {
          > lord-icon {
            width: 1.5rem;
            height: 1.5rem;
            filter: invert(0);
          }
          &:hover {
            font-weight: bold;
            background: transparent;
          }
        }

        .folder {
          display: flex;
          align-items: center;
          i {
            color: $base-font-col-mir;
            cursor: pointer;
          }
          > span:nth-child(2) {
            margin-right: auto !important;
          }
          > span:nth-child(n+3) {
            margin: 0 .5rem 0 0;
            display: none;
          }
          .dropdown-toggle:after {
            display: none;
          }
          .dropdown {
            div {
              padding: $padding $padding*2;
            }
            div:hover {
              background: $bright-gray-accent;
            }
          }
        }

        .in-folder {
          padding-left: $margin*4.5;
          position: relative;
        }

        .in-folder::before {
          content: "";
          position: absolute;
          left: $padding*2.5;
          top: 50%;
          height: 1px;
          width: $margin*1.5;
          background: $base-font-col;
        }
        .in-folder::after {
          content: "";
          position: absolute;
          left: $padding*2.5;
          top: $padding;
          height: calc(50% - $padding);
          width: 1px;
          background: $base-font-col;
        }

        .in-folder:hover::before, .in-folder:hover::after {
          background: $primary-col;
        }

        // INTEGRATIONS 

        .integrations-list {
          margin: 0 $margin $margin * 4 $margin;
          padding: 0 0 $padding 0;
          background: transparent;
          border-radius: $br;
          
          lord-icon {
            filter: invert(0);
          }

          a:not(#add-integration) {
            position: relative;
            padding-left: $padding*5;
          }

          a {
            font-size: $content-font-size;
          }

          a:hover {
            background: $secondary-col;
            color: $base-font-col;
          }

          img {
            height: $sidebar-icon-height * .8;
            position: absolute;
            left: $padding*1.5;
            top: $padding;
          }
        }

    }

      // DETAIL SIDEBAR

      .detail-sidebar {
        display: none;
        position: absolute;
        right: initial !important;
        height: 100%;
        transition: .3s all;
        width: $sidebar-width;
        padding-top: 0;
        background: $base-col;
        border-right: 1px solid $primary-col;
        box-shadow: 1px 1px 1px #f3f1fa;
        z-index: 12;

        &:after {
          bottom: 0 !important;
          background: $secondary-col !important;
        }

        .index-item {
          &:hover {
            border-radius: 5px;
            background: transparent;
            label {
              color: $primary-col !important;
            }
          }
        }

        .new-workspace {
          margin-top: $margin;
          &:hover {
            background: $secondary-col;
          }
          lord-icon:first-child {
            margin-right: $margin * 1.5;
            margin-left: 0;
            width: $margin * 3;
          }
          img {
            max-height: $logo-height;
            margin-right: $margin * 2;
          }
          span {
            font-size: $content-font-size;
          }
          lord-icon {
            margin-left: auto;
            max-height: $logo-height;
            width: $margin * 2;
          }
        }

        .index-wrapper {
          max-height: calc(100% - $header-height * 1.5);
          margin-bottom: 4rem;
          overflow-x: hidden;
          section {
            cursor: pointer;
            a {
              width: 100%;
            }
            a:hover {
              background: $secondary-col;
            }
          }
          > section:nth-child(n+5) {
            display: flex !important;
          }
        }
  
          // INDEX HEADER 
  
          .index-item {
            cursor: pointer;
            margin-top: $margin * 1;
          }
  
      }
      

    // SPREADSHEET 
    .table {
        position: relative;
        max-width: 100%;
        height: 100%;
        z-index: 15;
        // margin-top: - $header-height;
        #x-spreadsheet-demo {
          height: 100%;
          overflow: hidden;
        }
        .x-spreadsheet {
          height: 100%;
        }
        .x-spreadsheet-sheet {
            width: 100% !important;
            height: 100%;
            padding: 0;
            .x-spreadsheet-selector .x-spreadsheet-selector-area {
              border-color: #673AB7;
              background-color: #8773CD1A;
            }
            .x-spreadsheet-scrollbar.vertical {
              height: 100% !important;
            }
            .x-spreadsheet-scrollbar.horizontal {
              width: 100% !important;
            }
        }
    }

    // CHAT
    .chat {
        display: flex;
        flex-direction: column;
        overflow: visible;
        height: inherit;
        position: relative;
        width: $chat-width;
        max-height: 100%;
        z-index: 11;
        flex-grow: 1;
        &.min {
          ai-chat {
            position: absolute;
            z-index: 2;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 5em;
          }
          .chat_items {
            display: none;
          }
          app-spreadsheetsource {
            height: 100%;
          }
        }
        ai-chat {
          display: flex;
          justify-content: center;
          height: 100%;
          margin: auto;
          width: 100%;
        }
        #vhi_chat {
            height: inherit;
            width: 100%;
            max-width: 800px;
            display: flex;
            flex-direction: column;
            background: $base-col;
            transition: left 0.3s ease-in-out;
            .toolbar {
              width: 100%;
              height: 0;
              padding: 0 $padding*2;
              justify-content: flex-end;
              border-radius: $br 0 0 0;

              .clear-chat {
                margin-left: auto;
                lord-icon {
                  height: 1.25rem;
                  width: 1.25rem;
                  filter: invert(1);
                }
              }         
            }
            &.visible .toolbar {
            }
            &.hidden .toolbar {
              .hide-chat {
                lord-icon {
                  -webkit-transform: scaleX(-1);
                  transform: scaleX(-1);
                }
              }
            }
            &.hidden .vhi_element_wrapper {
              left: 0;
            }
            .vhi_element_wrapper {
                top: 2.5em;
                padding: 0 $padding*4 0 $padding*3;
                height: calc(100%);
                max-height: calc(100%);
                display: flex;
                // box-shadow: -1px 1px 2px hsla(209, 28%, 39%, 0.2);
                flex-direction: column;

                // EMPTY CHAT

                .vhi_empty_chat {
                  display: none;
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  left: 0;
                  top: 0;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                  letter-spacing: .025rem;
                  padding-bottom: 25%;
                  font-size: 1.25rem;
                  img {
                    max-width: 2.5rem;
                    margin-bottom: $margin * 2.5;
                  }
                }

                &.isEmpty {
                  .vhi_empty_chat {
                    display: flex;
                  }
                }
                
                // CHAT ITEMS 
                .chat_items {
                    position: relative;
                    flex-grow: 1;
                    mask-image: linear-gradient(to bottom, transparent 0%, black 2%);
                    padding: $padding / 2 0 $padding*2 0;
                    overflow: auto;
                    max-width: 800px;
                    min-width: 100%;
                    margin: auto;
                }

                // GPT CHAT-ITEM
                .vhi_item {
                    padding: 0;
                    margin-left: $margin*6;
                    margin-bottom: $margin*2;
                    position: relative;
                    font-size: 1rem;
                    line-height: 1.5rem;
                    // border-radius: $br;
                    // border-right: 1px solid hsla(209, 28%, 39%, 0.2);
                    // box-shadow: 0 1px 2px hsla(209, 28%, 39%, 0.2);

                    &.vhi-gpt-response {
                      .vhi-item-content > div:not(.vhi-item-name) {
                        margin-bottom: 1em;
                        &.current {
                          background: linear-gradient(0deg, $primary-col 1em, rgb(0, 0, 0) 2em, rgb(0, 0, 0) 100%);
                          background-clip: text;
                          -webkit-text-fill-color: transparent;
                        }
                      }
                    }

                    &.vhi-gpt-references, &.vhi-gpt-sources {
                      display: none; /*  TODO: Put it back in once it's safe to use */
                      &:before { display: none; }
                      ul {
                        padding: 0;
                        margin: $margin 0 $margin 0;
                        list-style: none;
                        font-size: 80%;
                        > li {
                          display: flex;
                          justify-content: flex-start;
                          align-items: flex-start;
                          margin-bottom: $margin;
                          > span {
                            margin-right: $margin;
                          }
                          > a {
                            display: block;
                            &:hover {
                              text-decoration: underline; 
                            }
                          }
                        }
                      }
                    }

                    app-ai-image {
                      max-width: $plot-max-width;
                      max-height: $plot-max-height;
                      margin-top: $margin*2;
                      margin-bottom: $margin*2;
                      display: block;
                      > a {
                        cursor: pointer;
                        position: relative;
                        display: block;
                        max-width: inherit;
                        max-height: inherit;
                        overflow: hidden;
                        border-radius: $margin;
                        box-shadow: 1px 1px 2px #000000b0;
                        > img {
                          max-width: inherit;
                          max-height: inherit;
                        }
                        > div { 
                          display: none;
                          font-size: 80%;
                          align-items: center;
                          width: 100%;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          padding: .3em;
                          background: #000000af;
                          color: #fff;
                          position: absolute;
                          left: 0;
                          bottom: 0;
                        }
                        &:hover {
                          > div {
                            display: flex;
                          }
                        }
                      }
                    }

                    .vhi-item-name {
                      font-weight: bold
                    }
                    
                    > .vhi-item-content {
                        white-space: pre-wrap;
                        a {
                          cursor: pointer;
                        }
                        position: relative;

                        .chat-message-info {
                          > button.info-button {
                            display: none;
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: $padding*4;
                            height: $padding*3;
                            border: none;
                            background: none;
                            cursor: pointer;
                            > lord-icon {
                              width: 100%;
                              height: 100%;
                              opacity: 0.3;
                            }
                            &:hover > lord-icon {
                              opacity: 0.5;
                            }
                          }

                          .chat-info-content {
                            border: 1px dashed $dark-gray;
                            padding: $padding;
                            margin-bottom: $margin*2;
                            background-color: #f6f0ff;

                            > .chat-info-timestamp {

                            }
                            > .chat-info-correlation-id {
                              font-size: 80%;
                              color: grey;
                              text-align: center;
                              margin-top: $margin*2;
                              > span {
                                font-style: italic;
                              }
                            }
                            > .chat-info-sources {
                              margin: $margin 0;
                              > div {
                                font-weight: bold;
                                margin-bottom: $margin;
                              }
                              > ul {
                                list-style: decimal;
                                margin: 0;
                                padding: 0 0 0 1em;
                                > li {
                                  &:hover {
                                    > a > div:first-child {
                                      font-weight: bold;
                                    }
                                  }
                                  > a {
                                    cursor: pointer;
                                    > div:first-child {

                                    }
                                    > div:last-child {
                                      font-size: 80%;
                                      color: grey;
                                      position: relative;
                                      text-indent: $padding*3;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }



                        &:hover .chat-message-info > button.info-button {
                          display: block;
                        }

                    }

                    > .vhi-item-timestamp {
                        display: none;
                        font-size: 80%;
                        margin-top: .5em;
                        text-align: right;
                        color: rgb(58, 58, 58);
                    }

                    &.in-progress .vhi-item-content {
                        padding: .5em;
                    }

                    .vhi-sources {
                        h3 { font-size: 1rem; margin-bottom: 1rem; }
                        .vhi-source {
                            display: block;
                            line-height: 1;
                            margin-bottom: 1rem;
                            .vhi-source-title {
                                color: var(--bs-body-color);
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                font-size: 90%;
                            }
                            .vhi-source-url {
                                font-size: 70%;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }

                    .dot-pulse {
                        margin-left: 1rem;
                        position: relative;
                        left: -9999px;
                        width: 10px;
                        top: $padding;
                        height: 10px;
                        border-radius: 5px;
                        background-color: #9880ff;
                        color: #9880ff;
                        box-shadow: 9999px 0 0 -5px;
                        animation: dot-pulse 1.5s infinite linear;
                        animation-delay: 0.25s;
                      }
                      .dot-pulse::before, .dot-pulse::after {
                        content: "";
                        display: inline-block;
                        position: absolute;
                        top: 0;
                        width: 10px;
                        height: 10px;
                        border-radius: 5px;
                        background-color: #9880ff;
                        color: #9880ff;
                      }
                      .dot-pulse::before {
                        box-shadow: 9984px 0 0 -5px;
                        animation: dot-pulse-before 1.5s infinite linear;
                        animation-delay: 0s;
                      }
                      .dot-pulse::after {
                        box-shadow: 10014px 0 0 -5px;
                        animation: dot-pulse-after 1.5s infinite linear;
                        animation-delay: 0.5s;
                      }
                      
                      @keyframes dot-pulse-before {
                        0% {
                          box-shadow: 9984px 0 0 -5px;
                        }
                        30% {
                          box-shadow: 9984px 0 0 2px;
                        }
                        60%, 100% {
                          box-shadow: 9984px 0 0 -5px;
                        }
                      }
                      @keyframes dot-pulse {
                        0% {
                          box-shadow: 9999px 0 0 -5px;
                        }
                        30% {
                          box-shadow: 9999px 0 0 2px;
                        }
                        60%, 100% {
                          box-shadow: 9999px 0 0 -5px;
                        }
                      }
                      @keyframes dot-pulse-after {
                        0% {
                          box-shadow: 10014px 0 0 -5px;
                        }
                        30% {
                          box-shadow: 10014px 0 0 2px;
                        }
                        60%, 100% {
                          box-shadow: 10014px 0 0 -5px;
                        }
                      }

                }

                .vhi_item:before {
                    content: "";
                    position: absolute;
                    left: -3rem;
                    top: $padding/2;
                    height: 2rem;
                    width: 2rem;
                    border-radius: $br;
                    background-color: $primary-col;
                    border-radius: 100%;
                }

                .vhi_item:after {
                    content: "";
                    position: absolute;
                    left: -2.75rem;
                    top: $padding;
                    height: 1.5rem;
                    width: 1.5rem;
                    background-image: url("https://app.talonic.ai/img/icons/neuron.png");
                    background-size: cover;
                    background-position: center;
                    background-size: 80%;
                    background-repeat: no-repeat;
                    filter: invert(1);
                    z-index: 1;
                }


                // USER CHAT-ITEM
                .vhi_item.vhi-user {
                   // background: $base-col;
                }

                .vhi_item.vhi-user:after {
                  display: none;
                }

                .vhi_item .vhi-item-user-initial {
                  font-size: 1.5rem;
                  height: 1.5rem;
                  width: 1rem;
                  color: white;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-image: none;
                  position: absolute;
                  left: -2.5rem;
                  top: $padding;
              }

                // RECOMMENDATIONS 
                .recommendations {
                    padding-top: $padding;
                    font-size: $content-font-size;
                    label {
                        padding-left: $padding*2;
                    }
                    .block {
                        margin-bottom: $margin;
                        padding: $padding/2 $padding*6 $padding/2 $padding*2; 
                        border-radius: $br;
                        position: relative;
                        cursor: pointer; 
                        > lord-icon {
                            display: none;
                            position: absolute;
                            height: 100%;
                            right: $padding;
                            top: 0;
                        }                       
                    }
                    .block:hover {
                        color: $base-col;
                        background-color: $primary-col;
                        > lord-icon {
                            display: block;
                            filter: invert(1);
                        }
                    }
                }

                // CHAT AI STATUS

                .chat-status {
                  padding-left: $padding * 5;
                  &.flash {
                    > div {
                      color: $primary-col !important;
                      transition: color 0.2s ease;
                    }
                  }
                  > div {
                    display: flex;
                    align-items: center;
                    color: $primary-col;
                    lord-icon {
                      margin-right: $margin;
                    }
                  }
                }

                // CHAT INPUT

                .vhi_element.input { 
                    position: relative;
                    margin: $margin 0 $margin*2 0;

                    &:hover {
                      #vhi-gpt-suggestions {
                        display: block;
                      }
                    }

                    #vhi-gpt-suggestions {
                      position: absolute;
                      display: none;
                      width: 100%;
                      bottom: 100%;
                      left: 0;
                      right: 0;
                      overflow: hidden;
                      font-size: 80%;
                      > div {
                        transform: translateY(100%);
                        opacity: 0;
                        // Animation duration is adjusted to fit within 500ms for all items
                        animation: suggestionSlideIn 0.3s ease-out forwards;
                        background-color: white;
                        box-shadow: 0px $margin*2 0px rgba(255, 255, 255, 0.5);
                        padding: $padding/2;
                        border: 1px solid $primary-col;
                        border-radius: 3px;
                        margin-bottom: $margin;
                        cursor: pointer;
                        &:hover {
                          background-color: $secondary-col;
                        }
                        &:nth-child(1) {
                          animation-delay: 0.2s;
                        }
                        &:nth-child(2) {
                          animation-delay: 0.1s;
                        }
                        &:nth-child(3) {
                          animation-delay: 0;
                        }
                      }
                    }
                    
                    #vhi-gpt-input {
                        width: 100%;
                        padding: $padding*2 $padding*11 $padding*2 $padding*2;
                        border-radius: $br;
                        border: none; 
                        border: 1px solid $secondary-col;
                        box-shadow: 0 1px 2px hsla(209, 28%, 39%, 0.2);
                    }
                    #vhi-gpt-input:focus {
                        outline-color: $primary-col;
                    }
                    #vhi-gpt-send {
                        position: absolute; 
                        right: $padding*2;
                        top: $padding*1.8;
                        cursor: pointer;
                    }
                    #stop-generating {
                        padding: $padding;
                        background: $base-col;
                        position: absolute;
                        width: 8rem;
                        text-align: center;
                        right: 1rem;
                        bottom: $margin;
                        border: 1px solid $secondary-col;
                        border-radius: $br;
                        box-shadow: 0 1px 2px hsla(209, 28%, 39%, 0.2);
                        cursor: pointer;
                    }
                    #stop-generating:hover {
                        background: $primary-col;
                        border: 1px solid $secondary-col;
                        color: $base-col;
                        font-weight: bold;
                    }

                    #chat-audio-input {
                      color: $base-font-col;
                      position: absolute; 
                      right: $padding*6;
                      top: $padding*0.6;
                      height: $padding*6;
                      width: $padding*6;
                      cursor: pointer;
                      border: none;
                      background: inherit;
                      border-radius: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      &:hover {
                        
                      }
                      &.recording {
                        background-color: $accent-gray;
                      }
                    }
                }
            }
        }
        app-spreadsheetsource {
          width: 100%;
          height: calc(100% - 5em);
          > iframe {
            width: 100%;
            height: 100%;
          }
          > #x-spreadsheet-s3 {
            width: 100%;
            height: 100%;
            > .x-spreadsheet {
              height: 100%;
              width: 100%;
              .x-spreadsheet-sheet {
                width: 100% !important;
                height: 100%;
              }
              .x-spreadsheet-selector .x-spreadsheet-selector-area {
                border-color: #673AB7;
                background-color: #8773CD1A;
              }
              .x-spreadsheet-scrollbar.horizontal {
                width: 100% !important;
              }
              .x-spreadsheet-scrollbar.vertical {
                height: 100% !important;
              }
            }
          }
        }

        &.slide {
          > .dragover-data {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            label {
              text-align: center;
              lord-icon {
                width: 80px;
                height: 80px;
              }
              p {
                font-size: 24px;
              }
            }

            &.dragover {
              @keyframes tilt-shaking {
                0% {
                  border: 0 solid $primary-col;
                }
                100% {
                  border: 10px solid $secondary-col;
                }
              }
              animation: tilt-shaking 1s linear infinite;
              > .drop-import {
                color: $primary-col;
              }
              background: #673ab724;
            }

            &.drop {

              @keyframes rotate {
                100% {
                  transform: rotate(1turn);
                }
              }
  
                background: $primary-col;
                position: relative;
                z-index: 0;
                overflow: hidden;
                
                &::before {
                  content: '';
                  position: absolute;
                  z-index: -2;
                  left: -50%;
                  top: -50%;
                  width: 200%;
                  height: 200%;
                  background-color: $primary-col;
                  background-repeat: no-repeat;
                  background-size: 50% 50%, 50% 50%;
                  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
                  background-image: linear-gradient($primary-col, $primary-col), linear-gradient($primary-col, $primary-col), linear-gradient($primary-col, $primary-col), linear-gradient($primary-col, $primary-col);
                  animation: rotate 4s linear infinite;
                }
                
                &::after {
                  content: '';
                  position: absolute;
                  z-index: -1;
                  left: 3px;
                  top: 3px;
                  width: calc(100% - 6px);
                  height: calc(100% - 6px);
                  background: $secondary-col;
                  border-radius: 3px;
                }
            }

          }

          
          > .file-picker {

            display: flex;
            flex-wrap: wrap;
            position: relative;
            box-shadow: 0 -1px 6px #5d5d5d;
            padding: 1rem 0 0 1rem;

            > div {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 4em;
              height: 4em;
              box-shadow: 2px 2px 2px #d4d4d4;
              border: 1px solid #8b8b8b;
              box-sizing: content-box;
              margin-right: 1rem;
              margin-bottom: 1rem;
              border-radius: 7px;
              text-align: center;

              > .file-picker-popup {
                display: none;
                padding: .3em;
                background: #fff;
                box-shadow: 0 -1px 6px #5d5d5d;
                position: absolute;
                bottom: 100%;
                width: 100%;
                left: 0;
                overflow-wrap: break-word;
                font-size: 90%;
                margin-bottom: -5px;
                text-align: center;
              }
              &:hover {
                cursor: pointer;
                box-shadow: 2px 2px 2px #838383;
                > .file-picker-popup {
                  display: block;
                }
              }

              > .file-picker-container {
                width: 4em;
                height: 4em;
                border-radius: 8px;
                position: relative;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                > .spinner-border {
                  width: 1.75em;
                  height: 1.75em;
                  display: none;
                  border-top-color: #767676;
                  border-left-color: #767676;
                  border-bottom-color: #767676;
                }
                > img {
                  width: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
                > lord-icon {
                  display: none;
                  position: static;
                  width: 100%;
                  height: 2em;

                  &.structured {
                    position: absolute;
                    right: .1em;
                    bottom: .1em;
                    width: 1em;
                    height: 1em;
                  }

                }

              }

            }

            // Upload tile
            > .file-picker-upload {
              box-sizing: border-box;
              label {
                display: flex;
                height: 100%;
                width: 100%;
                margin: 0;
                align-items: center;
                justify-content: center;
                cursor: pointer;
              }
              &:hover {
                @keyframes tilt-shaking {
                  0% {
                    border: 0 solid $primary-col;
                  }
                  100% {
                    border: 5px solid $secondary-col;
                  }
                }
                animation: tilt-shaking 1s linear infinite;
                > .drop-import {
                  color: $primary-col;
                }
              }
              &.dragover {
                @keyframes tilt-shaking {
                  0% {
                    border: 0 solid $primary-col;
                  }
                  100% {
                    border: 5px solid $secondary-col;
                  }
                }
                animation: tilt-shaking 1s linear infinite;
                > .drop-import {
                  color: $primary-col;
                }
                background: #673ab724;
              }
            }

            // Pending upload pseudo tile
            > .file-picker-uploading {
              > .file-picker-container {
                > .spinner-border {
                  display: block;
                }
              }
              &.active > .file-picker-container > .spinner-border {
                border-left-color: #000;
                border-top-color: #000;
                border-bottom-color: #000;
              }
            }

            // Uploaded file tile
            > .file-picker-file {

              // Tile is selected
              &.selected {
                border-color: #000;
              }
              // Tile is structured in database
              &.structured > .file-picker-container > lord-icon.structured {
                display: block;
              }
              // File is queued or processing
              &.queued, &.processing {
                > img {
                  opacity: .7;
                }
                > .file-picker-container {
                   > .spinner-border {
                    display: block;
                   }
                }
              }
              // File is processing
              &.processing {
                > .file-picker-container {
                  > span.spinner-border {
                    border-top-color: #000;
                    border-left-color: #000;
                    border-bottom-color: #000;
                  }
                }
              }
              // File processing failed
              &.failed {
                > .file-picker-container {
                  > lord-icon.failed {
                    display: block;
                  }
                }
              }
              // File finished processing
              &.finished {
                > .file-picker-container {
                  > lord-icon.finished {
                    display: block;
                  }
                }
              }
            }
          }
        }
    }
}

@keyframes suggestionSlideIn {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

#x-spreadsheet-demo {
    position: relative;
}

#vhi-gpt-loading {
    display: flex;
    z-index: 10;
    position: absolute;
    top: $margin*16;
    right: calc($chat-width + 3rem);
    width: 10rem;
    height: 10rem;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    .container {
        display: block;
        height: 100%;
        width: 100%;
        background: none;
        margin: 0 auto 3rem auto;
        transform: scale(0.5);
        > div {
            background: none;
        }
    }
    .vhi_element {
        width: 15em;
        height: 15em;
        .vhi {
            width: 100%;
            height: 100%;
            animation-iteration-count: infinite;
        }
    }
    .loading-text {
        opacity: 0;
        max-height: 0px;
        align-items: center;
        color: white;
        background: none;
        font-weight: bold;
        font-size: 1.05rem;
        letter-spacing: 0.02rem;
        &.first {
            animation: loading-text 5s 0s;
        }
        &.second {
            animation: loading-text 5s 5s;
        }
        &.third {
            animation: loading-text 5s 10s;
        }
        &.fourth {
            animation: loading-text 120s 15s;
        }
    }
    @keyframes loading-text {
        from {
          opacity: 1;
        }
        to {
          opacity: 2;
        }
    }
    .baton {
        display: block;
        height: 2px;
        width: 70px;
        background-color: #673AB7;
        animation: scale 1.25s infinite linear;
        -webkit-transform-origin: 0;
        -moz-transform-origin: 0;
        -ms-transform-origin: 0;
        -o-transform-origin: 0;
        transform-origin: 0;
        -webkit-transform: rotate(-10deg);
        -moz-transform: rotate(-10deg);
        -ms-transform: rotate(-10deg);
        -o-transform: rotate(-10deg);
        transform: rotate(-10deg);
      }
      .baton:before {
        content: "";
        display: block;
        height: 5px;
        width: 5px;
        background-color: #D1C4E9;
        position: absolute;
        top: -2px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        border-radius: 5px;
      }
      .baton:after {
        content: "";
        display: block;
        height: 5px;
        width: 5px;
        background-color: #D1C4E9;
        position: absolute;
        top: -2px;
        right: 0;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        border-radius: 5px;
      }
      
      .metronome {
        -webkit-transform-origin: 0;
        -moz-transform-origin: 0;
        -ms-transform-origin: 0;
        -o-transform-origin: 0;
        transform-origin: 0;
        animation: metronome 1.25s infinite linear;
      }
      
      .baton-0 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
      }
      .baton-0 .baton, .baton-0 .baton:after, .baton-0 .metronome {
        animation-delay: 0s;
      }
      
      .baton-1 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(10deg);
        -moz-transform: rotate(10deg);
        -ms-transform: rotate(10deg);
        -o-transform: rotate(10deg);
      }
      .baton-1 .baton, .baton-1 .baton:after, .baton-1 .metronome {
        animation-delay: -0.14s;
      }
      
      .baton-2 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(20deg);
        -moz-transform: rotate(20deg);
        -ms-transform: rotate(20deg);
        -o-transform: rotate(20deg);
      }
      .baton-2 .baton, .baton-2 .baton:after, .baton-2 .metronome {
        animation-delay: -0.28s;
      }
      
      .baton-3 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(30deg);
        -moz-transform: rotate(30deg);
        -ms-transform: rotate(30deg);
        -o-transform: rotate(30deg);
      }
      .baton-3 .baton, .baton-3 .baton:after, .baton-3 .metronome {
        animation-delay: -0.42s;
      }
      
      .baton-4 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(40deg);
        -moz-transform: rotate(40deg);
        -ms-transform: rotate(40deg);
        -o-transform: rotate(40deg);
      }
      .baton-4 .baton, .baton-4 .baton:after, .baton-4 .metronome {
        animation-delay: -0.56s;
      }
      
      .baton-5 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(50deg);
        -moz-transform: rotate(50deg);
        -ms-transform: rotate(50deg);
        -o-transform: rotate(50deg);
      }
      .baton-5 .baton, .baton-5 .baton:after, .baton-5 .metronome {
        animation-delay: -0.7s;
      }
      
      .baton-6 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(60deg);
        -moz-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
        -o-transform: rotate(60deg);
      }
      .baton-6 .baton, .baton-6 .baton:after, .baton-6 .metronome {
        animation-delay: -0.84s;
      }
      
      .baton-7 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(70deg);
        -moz-transform: rotate(70deg);
        -ms-transform: rotate(70deg);
        -o-transform: rotate(70deg);
      }
      .baton-7 .baton, .baton-7 .baton:after, .baton-7 .metronome {
        animation-delay: -0.98s;
      }
      
      .baton-8 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(80deg);
        -moz-transform: rotate(80deg);
        -ms-transform: rotate(80deg);
        -o-transform: rotate(80deg);
      }
      .baton-8 .baton, .baton-8 .baton:after, .baton-8 .metronome {
        animation-delay: -1.12s;
      }
      
      .baton-9 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
      }
      .baton-9 .baton, .baton-9 .baton:after, .baton-9 .metronome {
        animation-delay: -1.26s;
      }
      
      .baton-10 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(100deg);
        -moz-transform: rotate(100deg);
        -ms-transform: rotate(100deg);
        -o-transform: rotate(100deg);
      }
      .baton-10 .baton, .baton-10 .baton:after, .baton-10 .metronome {
        animation-delay: -1.4s;
      }
      
      .baton-11 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(110deg);
        -moz-transform: rotate(110deg);
        -ms-transform: rotate(110deg);
        -o-transform: rotate(110deg);
      }
      .baton-11 .baton, .baton-11 .baton:after, .baton-11 .metronome {
        animation-delay: -1.54s;
      }
      
      .baton-12 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(120deg);
        -moz-transform: rotate(120deg);
        -ms-transform: rotate(120deg);
        -o-transform: rotate(120deg);
      }
      .baton-12 .baton, .baton-12 .baton:after, .baton-12 .metronome {
        animation-delay: -1.68s;
      }
      
      .baton-13 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(130deg);
        -moz-transform: rotate(130deg);
        -ms-transform: rotate(130deg);
        -o-transform: rotate(130deg);
      }
      .baton-13 .baton, .baton-13 .baton:after, .baton-13 .metronome {
        animation-delay: -1.82s;
      }
      
      .baton-14 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(140deg);
        -moz-transform: rotate(140deg);
        -ms-transform: rotate(140deg);
        -o-transform: rotate(140deg);
      }
      .baton-14 .baton, .baton-14 .baton:after, .baton-14 .metronome {
        animation-delay: -1.96s;
      }
      
      .baton-15 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(150deg);
        -moz-transform: rotate(150deg);
        -ms-transform: rotate(150deg);
        -o-transform: rotate(150deg);
      }
      .baton-15 .baton, .baton-15 .baton:after, .baton-15 .metronome {
        animation-delay: -2.1s;
      }
      
      .baton-16 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(160deg);
        -moz-transform: rotate(160deg);
        -ms-transform: rotate(160deg);
        -o-transform: rotate(160deg);
      }
      .baton-16 .baton, .baton-16 .baton:after, .baton-16 .metronome {
        animation-delay: -2.24s;
      }
      
      .baton-17 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(170deg);
        -moz-transform: rotate(170deg);
        -ms-transform: rotate(170deg);
        -o-transform: rotate(170deg);
      }
      .baton-17 .baton, .baton-17 .baton:after, .baton-17 .metronome {
        animation-delay: -2.38s;
      }
      
      .baton-18 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
      }
      .baton-18 .baton, .baton-18 .baton:after, .baton-18 .metronome {
        animation-delay: -2.52s;
      }
      
      .baton-19 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(190deg);
        -moz-transform: rotate(190deg);
        -ms-transform: rotate(190deg);
        -o-transform: rotate(190deg);
      }
      .baton-19 .baton, .baton-19 .baton:after, .baton-19 .metronome {
        animation-delay: -2.66s;
      }
      
      .baton-20 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(200deg);
        -moz-transform: rotate(200deg);
        -ms-transform: rotate(200deg);
        -o-transform: rotate(200deg);
      }
      .baton-20 .baton, .baton-20 .baton:after, .baton-20 .metronome {
        animation-delay: -2.8s;
      }
      
      .baton-21 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(210deg);
        -moz-transform: rotate(210deg);
        -ms-transform: rotate(210deg);
        -o-transform: rotate(210deg);
      }
      .baton-21 .baton, .baton-21 .baton:after, .baton-21 .metronome {
        animation-delay: -2.94s;
      }
      
      .baton-22 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(220deg);
        -moz-transform: rotate(220deg);
        -ms-transform: rotate(220deg);
        -o-transform: rotate(220deg);
      }
      .baton-22 .baton, .baton-22 .baton:after, .baton-22 .metronome {
        animation-delay: -3.08s;
      }
      
      .baton-23 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(230deg);
        -moz-transform: rotate(230deg);
        -ms-transform: rotate(230deg);
        -o-transform: rotate(230deg);
      }
      .baton-23 .baton, .baton-23 .baton:after, .baton-23 .metronome {
        animation-delay: -3.22s;
      }
      
      .baton-24 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(240deg);
        -moz-transform: rotate(240deg);
        -ms-transform: rotate(240deg);
        -o-transform: rotate(240deg);
      }
      .baton-24 .baton, .baton-24 .baton:after, .baton-24 .metronome {
        animation-delay: -3.36s;
      }
      
      .baton-25 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(250deg);
        -moz-transform: rotate(250deg);
        -ms-transform: rotate(250deg);
        -o-transform: rotate(250deg);
      }
      .baton-25 .baton, .baton-25 .baton:after, .baton-25 .metronome {
        animation-delay: -3.5s;
      }
      
      .baton-26 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(260deg);
        -moz-transform: rotate(260deg);
        -ms-transform: rotate(260deg);
        -o-transform: rotate(260deg);
      }
      .baton-26 .baton, .baton-26 .baton:after, .baton-26 .metronome {
        animation-delay: -3.64s;
      }
      
      .baton-27 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(270deg);
        -moz-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        -o-transform: rotate(270deg);
      }
      .baton-27 .baton, .baton-27 .baton:after, .baton-27 .metronome {
        animation-delay: -3.78s;
      }
      
      .baton-28 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(280deg);
        -moz-transform: rotate(280deg);
        -ms-transform: rotate(280deg);
        -o-transform: rotate(280deg);
      }
      .baton-28 .baton, .baton-28 .baton:after, .baton-28 .metronome {
        animation-delay: -3.92s;
      }
      
      .baton-29 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(290deg);
        -moz-transform: rotate(290deg);
        -ms-transform: rotate(290deg);
        -o-transform: rotate(290deg);
      }
      .baton-29 .baton, .baton-29 .baton:after, .baton-29 .metronome {
        animation-delay: -4.06s;
      }
      
      .baton-30 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(300deg);
        -moz-transform: rotate(300deg);
        -ms-transform: rotate(300deg);
        -o-transform: rotate(300deg);
      }
      .baton-30 .baton, .baton-30 .baton:after, .baton-30 .metronome {
        animation-delay: -4.2s;
      }
      
      .baton-31 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(310deg);
        -moz-transform: rotate(310deg);
        -ms-transform: rotate(310deg);
        -o-transform: rotate(310deg);
      }
      .baton-31 .baton, .baton-31 .baton:after, .baton-31 .metronome {
        animation-delay: -4.34s;
      }
      
      .baton-32 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(320deg);
        -moz-transform: rotate(320deg);
        -ms-transform: rotate(320deg);
        -o-transform: rotate(320deg);
      }
      .baton-32 .baton, .baton-32 .baton:after, .baton-32 .metronome {
        animation-delay: -4.48s;
      }
      
      .baton-33 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(330deg);
        -moz-transform: rotate(330deg);
        -ms-transform: rotate(330deg);
        -o-transform: rotate(330deg);
      }
      .baton-33 .baton, .baton-33 .baton:after, .baton-33 .metronome {
        animation-delay: -4.62s;
      }
      
      .baton-34 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(340deg);
        -moz-transform: rotate(340deg);
        -ms-transform: rotate(340deg);
        -o-transform: rotate(340deg);
      }
      .baton-34 .baton, .baton-34 .baton:after, .baton-34 .metronome {
        animation-delay: -4.76s;
      }
      
      .baton-35 {
        height: 1px;
        width: 150px;
        position: absolute;
        top: calc(50% - 8px);
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
        -webkit-transform: rotate(350deg);
        -moz-transform: rotate(350deg);
        -ms-transform: rotate(350deg);
        -o-transform: rotate(350deg);
      }
      .baton-35 .baton, .baton-35 .baton:after, .baton-35 .metronome {
        animation-delay: -4.9s;
      }
      
      @keyframes metronome {
        0% {
          -webkit-transform: rotate(-25deg);
          -moz-transform: rotate(-25deg);
          -ms-transform: rotate(-25deg);
          -o-transform: rotate(-25deg);
          transform: rotate(-25deg);
        }
        50% {
          -webkit-transform: rotate(25deg);
          -moz-transform: rotate(25deg);
          -ms-transform: rotate(25deg);
          -o-transform: rotate(25deg);
          transform: rotate(25deg);
        }
        100% {
          -webkit-transform: rotate(-25deg);
          -moz-transform: rotate(-25deg);
          -ms-transform: rotate(-25deg);
          -o-transform: rotate(-25deg);
          transform: rotate(-25deg);
        }
      }
      @keyframes scale {
        0% {
          -webkit-transform: scaleX(1);
          -moz-transform: scaleX(1);
          -ms-transform: scaleX(1);
          -o-transform: scaleX(1);
          transform: scaleX(1);
        }
        25% {
          -webkit-transform: scaleX(0.74);
          -moz-transform: scaleX(0.74);
          -ms-transform: scaleX(0.74);
          -o-transform: scaleX(0.74);
          transform: scaleX(0.74);
        }
        50% {
          -webkit-transform: scaleX(1);
          -moz-transform: scaleX(1);
          -ms-transform: scaleX(1);
          -o-transform: scaleX(1);
          transform: scaleX(1);
        }
        75% {
          -webkit-transform: scaleX(1.16);
          -moz-transform: scaleX(1.16);
          -ms-transform: scaleX(1.16);
          -o-transform: scaleX(1.16);
          transform: scaleX(1.16);
        }
        100% {
          -webkit-transform: scaleX(1);
          -moz-transform: scaleX(1);
          -ms-transform: scaleX(1);
          -o-transform: scaleX(1);
          transform: scaleX(1);
        }
      }
}



// BOOTSTRAP CLEAN

.lordicon-placeholder {
    background: $primary-col;
}

.row {
     --bs-gutter-x: 1.5rem;
     --bs-gutter-y: 0;
     display: flex;
     flex-wrap: nowrap;
     margin-top: 0;
     margin-right: 0; 
     margin-left: 0;
     > * {
        padding: 0;
     }
}

// TABLE TOOLBAR HIDE 

.x-spreadsheet-toolbar-btn:nth-child(3), 
.x-spreadsheet-toolbar-btn:nth-child(4),
.x-spreadsheet-toolbar-btn:nth-child(5), 
.x-spreadsheet-toolbar-btn:nth-child(16), 
.x-spreadsheet-toolbar-btn:nth-child(18), 
.x-spreadsheet-toolbar-btn:nth-child(19), 
.x-spreadsheet-toolbar-btn:nth-child(20), 
.x-spreadsheet-toolbar-btn:nth-child(21), 
.x-spreadsheet-toolbar-btn:nth-child(24), 
.x-spreadsheet-toolbar-btn:nth-child(25), 
.x-spreadsheet-toolbar-btn:nth-child(26), 
.x-spreadsheet-toolbar-btn:nth-child(27), 
.x-spreadsheet-toolbar-btn:nth-child(28) {
    display: none;
}


// PROFILE

#content-wrapper {
  .return-from-account {
    position: absolute;
    padding: 0;
    left: $padding * 2;
    top: -$padding * 5;
    color: black;
    text-decoration: none;
    transition: all 0.3s;
  }
  &.sidebar-on {
    .return-from-account {
      margin-left: $sidebar-width;
    }
    .sidebar-toggle {
      left: - $margin * 2;
    }
  }
}

.profile-header {
  height: $header-height;
  width: 70%;
  position: absolute;
  top: -$header-height;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 $padding * 2 0 $padding;
    height: 2rem;
    background: $secondary-col;
    color: $base-font-col;
    border: 0;
    border-radius: $br;
    img {
      max-height: 1.5rem;
      transform: rotate(180deg);
    }
    &:hover {
      background: $primary-col;
      color: $base-font-col-mir;
      img {
        filter: invert(1);
      }
    }
  }
}

app-profile {
  position: relative;
  height: 100%;
  overflow: auto;

  .profile {
    border-top: 1px solid $bright-gray-accent;
    overflow: auto;
    padding-bottom: $padding*15;
  }

  > * {
  background: $base-col;
  padding: $padding $padding * 2;
  margin: 0;

  .user-avatar {
    height: 5rem;
    width: 5rem;
    background: $primary-col;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $base-font-col-mir;
    font-size: 2.5rem;
  }
}

.profile-block {
  margin-bottom: $margin * 2;
  > .row {
    display: flex;
    flex-direction: row;
    align-items: center; 
    width: initial;
    > div:first-child { 
      min-width: 50%;
      flex-shrink: initial;
    }
  }
  > * {
    min-width: 50%;
    width: initial;
  }
  > *:first-child {
    padding-right: $margin * 4;
  }
}

}

// PROFILE SIDEBAR 
  .sidebar {
    background: white !important;
    min-width: $sidebar-width;
    max-width: $sidebar-width;
    min-height: 100vh;
    max-height: 100vh;
    left: - calc($sidebar-width + $padding);
    top: -$header-height;
    overflow: auto;
    width: initial;
    position: absolute;
    padding: calc($header-height + $toolbar-height + $padding) $padding $padding $padding;
    overflow: hidden;
    display: flex;
    flex-direction: column;   
  }


  // LOGIN 

  .auth {
    padding: $padding * 2;
    a {
      color: $primary-col;
      cursor: pointer;
    }
    .unstyled-a {
      color: $dark-gray;
      margin-top: $margin;
      &:hover {
        color: $primary-col;
      }
    }
    > .row {
      align-items: center;
    }
    .row > fieldset {
      max-width: 68%;
      .col-4 {
        width: 50%;
        .form-control-sm {
          padding: $padding;
        }
      }
    }
    .form-label {
      margin: $margin 0 0 0;
      padding: 0;
    }
    .form-control {
      padding: $padding;
    }
    .text-danger > div {
      margin-top: $margin;
      color: $primary-col;
    }
    .fineprint {
      margin-top: $margin;
      font-size: .8rem;
      color: $dark-gray;
      a {
        color: $dark-gray;
      }
    }

    [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
      margin: 0 $margin $margin 0;
      &:hover {
        background: $primary-col;
        color: $base-font-col-mir;
      }
    }

    h5 {
      margin-bottom: $margin * 2;
    }
    .skip {
      margin-top: $margin;
      margin-left: $margin * 2;
    }

    .sign-in-offer {
      margin-top: $margin * 2;
      font-size: .8rem;
      a {
        margin-left: $margin;
        color: $primary-col;
        position: relative;
        text-decoration: none;
        &:hover {
          font-weight: bold;
          &:after {
            height: 2px;
          }
        }
        &:after {
          content: "";
          position: absolute;
          bottom: -$padding / 5;
          left: 0;
          width: 100%;
          height: 1px;
          background: $primary-col;
        }
      }
    }
    .progress {
        height: $margin * 3;
        border-radius: $br;
        margin: $margin * 2 0 $margin 0;
        .progress {
          background-color: $secondary-col;
        }
        .text-bg-success {
          background-color: $primary-col !important;
        }
    }
    .full-vp-message {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      height: 50vh;
      .row {
        align-items: center;
        position: relative;
        h1 {

        }
        lord-icon {
          position: absolute;
          left: -6rem;
          margin: -2.5rem -1rem 0 0;
          width: 5rem;
          height: 5rem;
        }
      }
      > div:nth-child(2) {
        margin-top: 1rem;
      }
      p {
        margin-bottom: $margin * 2;
        a {
          color: black;
        }
      }
      p:first-child {
        color: $primary-col;
      }
    }
  }

  .auth-logo {
    img {
      width: $logo-width;
      height: auto;
    }
  }

  button.branded-col, button.branded-col.btn-success {
    background: $primary-col;
    color: $base-font-col-mir;
    border: none;
    padding: $padding $padding * 2;
    margin-bottom: $margin;
    a {
      color: $base-font-col-mir;
    }
    &:hover {
      background-color: $secondary-col;
      color: $primary-col;
      a {
        color: $primary-col;
      }
    }
  }
  
  .card {
    border: none;
    margin-top: $margin;
    .card-body {
      margin-top: $margin * 2;
      padding: 0;
      > form > * {
        margin-bottom: $margin;
      }
      .card-flex {
        display: flex;
        > div {
          padding: $padding;
          white-space: nowrap;
        }
      }
      label {
        display: block;
      }
      input, select {
        min-width: 18em;
      }
    }
  }

  .sign-up-promo {
    h3 {
      color: $base-font-col-mir;
    }
    background-color: $secondary-col;
    background-image: url("/assets/img/backgrounds/signup.webp");
    background-size: cover;
    max-width: 25%;
    min-height: 70vh;
    padding: $padding * 4;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    border-radius: $br;
    p {
      color: $base-font-col-mir;
    }
    button {
      margin: $margin auto 0 auto;
      padding: $padding $padding * 2 ;
      a {
        text-decoration: none;
      }
    }
  }

  .integration-bottom-nav {
    padding: $padding;
    display: flex;
    justify-content: end;
    > .integration-return {
      background-color: $primary-col;
      color: $gray-font;
      padding: 0 $padding;
      display: inline-block;
      cursor: pointer;
      &:hover {
        color: $accent-gray;
      }
      > img {
        height: $icon-height * 1.5;
        width: $icon-height * 1.5;
        filter: invert(1);
        transform: rotate(180deg);
      }
    }
  }